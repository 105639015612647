/* News.css */

/* Container for the entire page */
.news-container {
    max-width: 1200px;
    /* or your desired page width */
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: "Georgia", serif;
    /* or your chosen font */
    margin-top: 5vh;
}

/* Title & subtitle */
.news-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.news-subtitle {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #666;
}

/* The grid for all news cards */
.news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    /* or for a strict 3-column layout:
     grid-template-columns: repeat(3, 1fr);
  */
}

/* Each card */
.news-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* so the image corners match the card rounding if desired */
    transition: box-shadow 0.2s ease-in-out;
}

.news-card:hover {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}

/* Card image area */
.news-image img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

/* Card content area */
.news-content {
    padding: 1rem;
}

.news-content h2 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.news-content p {
    font-size: 0.95rem;
    line-height: 1.4;
    color: #555;
    margin-bottom: 1rem;
}

/* Continue reading button (could be a link) */
.news-continue {
    background-color: transparent;
    border: none;
    color: #8c993e;
    /* example brand color */
    font-weight: 600;
    cursor: pointer;
    font-size: 0.95rem;
    padding: 0;
}

.news-continue:hover {
    text-decoration: underline;
}

/* "More news" button at bottom */
.news-load-more {
    text-align: center;
    margin-top: 2rem;
}

.news-load-more button {
    background-color: #d8c65f;
    /* example brand color (yellowish) */
    border: none;
    border-radius: 20px;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
}

.news-load-more button:hover {
    background-color: #c5b24d;
}