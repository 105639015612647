/* Navbar.css */

/* A reset or global margin/padding removal is often helpful */
html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    /* height: 100%; // if needed for full-page layouts */
}

/* The header wraps the nav and positions it above the hero */
.navbar-header {
    position: absolute;
    /* Sits on top of hero */
    top: 1rem;
    /* Adjust vertical offset */
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    /* Or a specific max-width if you prefer */
    z-index: 999;
    /* Ensure it appears above hero content */
}

/* The main container for the navbar (white pill shape) */
.navbar-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    gap: 2rem;
}

/* LOGO area */
.navbar-logo img {
    height: 40px;
    /* Adjust as you like */
    width: auto;
}

/* NAV LINKS (middle) */
.navbar-links {
    list-style: none;
    display: flex;
    gap: 2rem;
    margin: 0;
    padding: 0;
}

/* Basic link styling */
.navbar-links a {
    text-decoration: none;
    color: #333;
    padding: 0.5rem 0;
}

.navbar-links a:hover {
    color: #8c993e;
    /* example hover color (greenish) */
}

/* DROPDOWN */
.dropdown {
    position: relative;
    cursor: pointer;
    /* show pointer on "OUR PRODUCE" text */
}

.dropdown-toggle {
    font-weight: 350;
    display: inline-flex;
    align-items: center;
}

/* caret arrow next to "OUR PRODUCE" */
.dropdown-arrow {
    margin-left: 0.3rem;
    font-size: 0.8rem;
}

/* The hidden menu */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    list-style: none;
    margin: 0;
    padding: 0.5rem 1rem;
    min-width: 160px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    z-index: 9999;
    /* Hide by default (will be shown on hover for desktop) */
    display: none;
}

/* Desktop hover effect - show dropdown on hover */
@media (min-width: 769px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}

/* For mobile - explicit class to make the dropdown visible */
.dropdown-menu-visible {
    display: block;
}

/* The dropdown links */
.dropdown-menu li a {
    display: block;
    padding: 0.4rem 0;
    color: #333;
}

.dropdown-menu li a:hover {
    color: #8c993e;
}

@media (hover: none) {}

/* RIGHT: Language Button */

/* Example of a small pill button for language */
.lang-arrow {
    margin-left: 0.3rem;
    font-size: 0.7rem;
}

.navbar-lang {
    margin-left: auto;
    /* Push to the right */
}

.lang-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0.5rem 2rem 0.5rem 1.2rem;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.8rem center;
    background-size: 0.8em auto;
}

.navbar-lang {
    margin-left: auto;
}

.lang-dropdown {
    position: relative;
    display: inline-block;
}

.lang-btn {
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0.2rem 0.8rem;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.lang-dropdown-content {
    display: none;
    position: absolute;
    right: -100%;
    transform: translateX(-50%);
    top: 0%;
    background-color: #1a1a1a;
    min-width: 80px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.lang-dropdown:hover .lang-dropdown-content {
    display: block;
}

.lang-dropdown-content button {
    color: white;
    padding: 0.7rem 1.5rem;
    text-decoration: none;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.lang-dropdown-content button:hover {
    background-color: #333;
}

.lang-dropdown-content button.active {
    background-color: #333;
}


/* Add hover effect to main button */
.lang-dropdown:hover .lang-btn {
    background-color: #333;
    border-radius: 25px 25px 0 0;
}


/* Responsive adjustments */
@media (max-width: 769px) {
    .navbar-header {
        position: absolute;
        /* Sits on top of hero */
        top: 0rem;
        /* Adjust vertical offset */
        width: auto;
        /* Or a specific max-width if you prefer */
        z-index: 999;
        /* Ensure it appears above hero content */
    }

    /* The main container for the navbar (white pill shape) */
    .navbar-container {
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 0;
        padding: 0.5rem 1rem;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        gap: 2rem;
    }


    .lang-btn {
        padding: 0.4rem 1.2rem;
        font-size: 0.8rem;
    }

    .lang-dropdown-content button {
        padding: 0.5rem 1.2rem;
    }
}

/* Mobile-specific styles (768px and below) */
@media screen and (max-width: 768px) {

    /* Reset position for mobile */
    .navbar-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transform: none;
        z-index: 999;
        background-color: white;
    }

    /* Adjust container for mobile */
    .navbar-container {
        border-radius: 0;
        padding: 1rem;
        justify-content: space-between;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
        gap: 0;
    }

    /* Adjust logo size */
    .navbar-logo img {
        height: 30px;
    }

    /* Hide desktop links by default */
    .navbar-links {
        display: none;
        position: absolute;
        flex-direction: column;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 0;
        margin: 0;
        gap: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    /* Show links when menu is open */
    .navbar-links.open {
        display: flex;
    }

    /* Style mobile menu items */
    .navbar-links li {
        width: 100%;
        border-bottom: 1px solid #eee;
    }

    .navbar-links a,
    .navbar-links .dropdown-toggle {
        display: block;
        padding: 1rem;
        color: #333;
        font-weight: 500;
    }

    /* Mobile menu hamburger button */
    .mobile-menu-btn {
        display: block;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #333;
        margin-right: 1rem;
        margin-left: auto;
    }

    /* Mobile dropdown adjustments */
    .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        width: 100%;
    }

    .dropdown-menu {
        position: static;
        box-shadow: none;
        width: 100%;
        padding: 0;
        background-color: #f9f9f9;
    }

    .dropdown-menu li {
        border-bottom: none;
    }

    .dropdown-menu li a {
        padding: 0.8rem 1.5rem;
        display: flex;
        justify-content: space-between;
    }

    /* Add arrow for submenu items */
    .dropdown-menu li a::after {
        content: '→';
        margin-left: 0.5rem;
    }

    /* Mobile language selector */
    .navbar-lang {
        display: none;
        /* Hide from navbar */
    }

    /* Language selector inside mobile menu */
    .mobile-navbar-lang {
        display: flex;
        justify-content: center;
        border-top: 1px solid #eee;
        margin-top: 1rem;
        width: 100%;
    }

    .mobile-lang-dropdown-content {
        display: flex;
        width: 100%;
    }

    .mobile-lang-dropdown-content button {
        width: 50%;
        padding: 0.8rem;
        background-color: #f9d342;
        border: none;
        cursor: pointer;
        font-weight: bold;
        color: black;
    }

    .mobile-lang-dropdown-content button.active {
        background-color: #c7a62c;
    }

    /* User circle in top right (third variant) */
    .user-circle {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f9d342;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #333;
        z-index: 1000;
    }

    /* Circle icon grid for categories */
    .category-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        padding: 1.5rem;
        background-color: white;
    }

    .category-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .circle-icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        border: 1px solid #eee;
    }

    .circle-icon img {
        width: 40px;
        height: 40px;
    }

    .category-title {
        font-size: 0.9rem;
        color: #555;
    }
}