.mission-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem 1rem;
    flex-wrap: wrap;
}

/* Left text area */
.mission-text {
    flex: 1;
    min-width: 300px;
}

.mission-text h2 {
    margin-bottom: 1rem;
}

.mission-text h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.mission-text p {
    margin-bottom: 1rem;
    line-height: 1.6;
}

/* Right images grid */
.mission-images-grid {
    flex: 1;
    display: grid;
    /* Create 3 equal columns so we can span images over 2 columns when needed */
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    /* Force each row to a fixed height for consistent cropping/scaling */
    grid-auto-rows: 200px;
    min-width: 300px;
}

/* Make sure all images fill their grid cell and crop appropriately */
.mission-images-grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

/* 
   Top Row: 
   - .img1 (top left) spans columns 1-2 (wider)
   - .img2 (top right) occupies column 3 (narrow)
*/
.img1 {
    grid-column: 1 / span 2;
}

.img2 {
    grid-column: 3;
}

/* 
   Bottom Row:
   - .img3 (bottom left) occupies column 1 (narrow, similar to top right)
   - .img4 (bottom right) spans columns 2-3 (wider)
*/
.img3 {
    grid-column: 1;
}

.img4 {
    grid-column: 2 / span 2;
}

/* Mobile Styles */
@media (max-width: 992px) {
    .mission-section {
        flex-direction: column;
        gap: 2rem;
    }

    .mission-text,
    .mission-images-grid {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .mission-section {
        padding: 1.5rem 1rem;
        gap: 1.5rem;
    }

    .mission-images-grid {
        grid-auto-rows: 150px;
    }
}

@media (max-width: 480px) {
    .mission-section {
        padding: 1rem;
        gap: 1rem;
    }

    .mission-text h2 {
        font-size: 1.5rem;
    }

    .mission-text h3 {
        font-size: 1.2rem;
    }

    .mission-images-grid {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 120px;
    }

    /* Adjust grid spans for mobile */
    .img1 {
        grid-column: 1 / span 2;
        grid-row: 1;
    }

    .img2 {
        grid-column: 1;
        grid-row: 2;
    }

    .img3 {
        grid-column: 2;
        grid-row: 2;
    }

    .img4 {
        grid-column: 1 / span 2;
        grid-row: 3;
    }
}