.partners-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-top: 5vh;
}

.partners-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
}

.partners-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
}

.partners-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
}

.partner-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    width: 180px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
}

.partner-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-placeholder {
    font-size: 36px;
    color: #aaa;
    font-style: italic;
    letter-spacing: 2px;
}

.see-more-link {
    font-size: 14px;
    color: #9966cc;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.see-more-link:hover {
    color: #7744aa;
}

.arrow {
    margin-left: 5px;
}

@media (max-width: 768px) {
    .partners-grid {
        justify-content: center;
    }
}