/* Contact.css */

/* Main container */
.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: "Georgia", serif;
    /* or your chosen font */
    color: #333;
    margin-top: 8vh;
}

/* Title */
.contact-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

/* Intro text */
.contact-intro {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #666;
}

/* Company details block */
.contact-details {
    margin-bottom: 2rem;
    line-height: 1.6;
}

/* Contact info (email & phone) */
.contact-info-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.contact-info-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.contact-info-text {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.contact-info-text a {
    color: #1a0dab;
    /* link color (blueish) */
    text-decoration: none;
}

.contact-info-text a:hover {
    text-decoration: underline;
}

/* optional icon styling if using react-icons
.contact-icon {
  font-size: 1.2rem;
  color: #d8c65f; 
}
*/

/* Phone text if not using an icon
.contact-info-item span {
  font-size: 1rem;
  color: #333;
}
*/

/* Social section */
.contact-social {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.contact-social p {
    margin: 0;
    font-weight: 500;
}

/* Social icons if you wanted to use react-icons
.social-icon {
  font-size: 1.2rem;
  color: #8c993e;
}
*/

.contact-social a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
}

.contact-social a:hover {
    text-decoration: underline;
}

/* Add these styles to your Contact.css file */

/* Map section */
.contact-map {
    margin-bottom: 2rem;
}

.map-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.map-container {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Make the map responsive */
.map-container iframe {
    display: block;
}

/* Optional: Add a hover effect */
.map-container:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease;
}

/* Add these media queries to your Contact.css file */

/* Base responsive adjustments */
@media screen and (max-width: 768px) {
    .contact-container {
        padding: 1.5rem 1rem;
        margin-top: 6vh;
    }

    .contact-title {
        font-size: 1.75rem;
    }

    .contact-intro {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    .map-container iframe {
        height: 350px;
    }
}

/* Mobile-specific adjustments */
@media screen and (max-width: 480px) {
    .contact-container {
        padding: 1rem 0.75rem;
        margin-top: 7vh;
    }




    .contact-title {
        font-size: 1.5rem;
    }

    .contact-details p {
        margin-bottom: 0.5rem;
    }

    .map-container iframe {
        height: 280px;
    }

    .contact-info-section {
        gap: 0.75rem;
    }

    .contact-social {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .contact-social p {
        margin-bottom: 0.25rem;
    }
}

/* Small phones */
@media screen and (max-width: 320px) {
    .contact-container {
        padding: 0.75rem 0.5rem;
    }

    .map-container iframe {
        height: 240px;
    }
}