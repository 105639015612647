/* Partners Section Styles */
.partners-section {
    padding: 3rem 1.5rem;
    background-color: #f9f9f9;
    margin-top: 2rem;
}

.partners-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #333;
}

.partners-carousel-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.partners-carousel {
    flex: 1;
    overflow: hidden;
    margin: 0 10px;
    touch-action: pan-y;
    /* Improve touch behavior */
}

.partners-carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
}

.partner-card {
    padding: 1rem;
    margin: 0 0.5rem;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex-shrink: 0;
}

.partner-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.partner-logo-container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.partner-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.partner-name {
    font-size: 1rem;
    color: #4a4a4a;
    margin: 0;
}

.carousel-control {
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    transition: background-color 0.3s ease;
    font-size: 1.5rem;
    color: #4a4a4a;
    flex-shrink: 0;
    /* Prevent buttons from shrinking */
}

.carousel-control:hover {
    background-color: #f0f0f0;
}

.carousel-prev {
    margin-right: 0.5rem;
}

.carousel-next {
    margin-left: 0.5rem;
}

.carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.carousel-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d1d5db;
    border: none;
    margin: 0 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0;
    /* Remove default button padding */
}

.carousel-dot.active {
    background-color: #4E8746;
    transform: scale(1.2);
    /* Slightly enlarge the active dot */
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .partners-carousel-container {
        padding: 0 10px;
    }
}

@media (max-width: 768px) {
    .partners-title {
        font-size: 2rem;
    }

    .carousel-control {
        width: 35px;
        height: 35px;
        font-size: 1.25rem;
    }

    .partner-card {
        margin: 0 0.3rem;
    }
}

@media (max-width: 480px) {
    .partners-section {
        padding: 2rem 0.5rem;
    }

    .partners-title {
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
    }

    .partner-card {
        padding: 0.75rem;
    }

    .partner-logo-container {
        height: 60px;
    }

    .partner-name {
        font-size: 0.9rem;
    }

    .carousel-control {
        width: 30px;
        height: 30px;
        font-size: 1rem;
    }

    /* Make dots larger on mobile for easier tapping */
    .carousel-dot {
        width: 12px;
        height: 12px;
        margin: 0 0.35rem;
    }
}