.home-container {
    margin: 0;
    padding: 0;
    font-family: serif;
}

.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-overlay {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
}

.hero-card {
    max-width: 1200px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 1rem;
}

.hero-about {
    padding-right: 2rem;
}

.hero-about h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.hero-about p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4a4a4a;
}

.hero-icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    align-content: center;
}

.hero-icon {
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hero-icon:hover {
    background: linear-gradient(145deg, #4E8746, #E7BD15);
}

.hero-icon:hover img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(100deg) brightness(200%) contrast(1);
}

.hero-icon:hover span {
    color: white;
}

.hero-icon img {
    width: 60px;
    height: auto;
    margin-bottom: 0.75rem;
    border-radius: 50%;
    padding: 0.5rem;
}

.hero-icon span {
    color: #4a4a4a;
    font-size: 0.9rem;
}

.hero-icon:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.scroll-down {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    cursor: pointer;
    color: transparent;
    animation: bounce 2s infinite;
}

.scroll-down .arrow {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2.5rem;
    color: white;
}

.content-section {
    padding: 1.5rem;
}

.featured-section {
    padding: 4rem 1.5rem;
    background-color: #f9f9f9;
}

.featured-section:nth-child(odd) {
    background-color: #2c2c17;
}


.featured-container {
    max-width: 100%;
    margin: 0 auto;
    display: grid;
}

.featured-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.featured-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;
}

.featured-content-reverse {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;
    direction: rtl;
}


.featured-content-reverse .featured-text,
.featured-content-reverse .featured-image-container {
    direction: ltr;
}

.featured-image-container {
    width: 80vmin;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.featured-image {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease;
    display: block;
    object-fit: cover;
    aspect-ratio: 16/9;
}

.featured-image:hover {
    transform: scale(1.05);
}

.featured-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.featured-text p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 2rem;
}

.featured-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.featured-content-reverse .featured-text p {
    color: #F6CC4F;
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 2rem;
}

.featured-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    color: blue;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featured-title-rev {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.featured-title-rev {
    color: #F6CC4F;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

/* Specific card colors */
.feature-card.blueberry {
    border-top: 4px solid #3b82f6;
}

.feature-card.blueberry .feature-card-header {
    background-color: #eff6ff;
}

.feature-card.blueberry .feature-card-link {
    color: #3b82f6;
}

.feature-card.blueberry .feature-card-link:hover {
    color: #2563eb;
}

.feature-card.hazelnut {
    border-top: 4px solid #b45309;
}

.feature-card.hazelnut .feature-card-header {
    background-color: #fef3c7;
}

.feature-card.hazelnut .feature-card-link {
    color: #b45309;
}

.feature-card.hazelnut .feature-card-link:hover {
    color: #92400e;
}

.feature-card.honey {
    border-top: 4px solid #f59e0b;
}

.feature-card.honey .feature-card-header {
    background-color: #fef3c7;
}

.feature-card.honey .feature-card-link {
    color: #f59e0b;
}

.feature-card.honey .feature-card-link:hover {
    color: #d97706;
}

.feature-card.tea {
    border-top: 4px solid #10b981;
}

.feature-card.tea .feature-card-header {
    background-color: #ecfdf5;
}

.feature-card.tea .feature-card-link {
    color: #10b981;
}

.feature-card.tea .feature-card-link:hover {
    color: #059669;
}

.feature-card.solar {
    border-top: 4px solid #f97316;
}

.feature-card.solar .feature-card-header {
    background-color: #fff7ed;
}

.feature-card.solar .feature-card-link {
    color: #f97316;
}

.feature-card.solar .feature-card-link:hover {
    color: #ea580c;
}

.feature-card.cold {
    border-top: 4px solid #0ea5e9;
}

.feature-card.cold .feature-card-header {
    background-color: #f0f9ff;
}

.feature-card.cold .feature-card-link {
    color: #0ea5e9;
}

.feature-card.cold .feature-card-link:hover {
    color: #0284c7;
}


/* Mobile Styles */
@media (max-width: 992px) {
    .featured-title {
        font-size: 2rem;
    }

    .featured-content {
        gap: 2rem;
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

/* Feature Cards Styling */
.feature-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #eaeaea;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.feature-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #f0f0f0;
}

.feature-card-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    margin: 0;
}

.feature-card-icon {
    font-size: 1.75rem;
}

.feature-card-content {
    padding: 16px 20px;
}

.feature-card-text {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #4a4a4a;
    margin: 0 0 16px 0;
}

.feature-card-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #3182ce;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.2s ease;
}

.feature-card-link:hover {
    color: #2c5282;
}

.feature-card-arrow {
    margin-left: 4px;
}

/* Responsive styling */
@media (max-width: 992px) {
    .feature-cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .feature-cards-container {
        grid-template-columns: 1fr;
        padding: 15px;
    }

    .feature-card-header {
        padding: 14px 16px;
    }

    .feature-card-content {
        padding: 14px 16px;
    }
}

/* Mobile Styles */
@media (max-width: 992px) {
    .hero-card {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 1.5rem;
        margin: 1rem 0.5rem;
    }

    .hero-about {
        padding-right: 0;
    }

    .hero-about h2 {
        font-size: 2rem;
    }

    .hero-about p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .scroll-down {
        display: none;
    }

    .home-container {
        margin-top: 1.5vh;
    }

    .hero-section {
        height: auto;
        min-height: 100vh;
        padding: 4rem 0 2rem;
    }

    .hero-card {
        margin-top: 0;
        border-radius: 15px;
        padding: 1.25rem;
    }

    .hero-icons {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .hero-icon img {
        width: 50px;
    }
}

@media (max-width: 480px) {
    .hero-section {
        padding: 2rem 0 1rem;
    }

    .hero-card {
        padding: 1rem;
        margin: 0.5rem;
    }

    .hero-about h2 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    .hero-about p {
        font-size: 0.9rem;
        line-height: 1.6;
    }

    .hero-icons {
        gap: 0.75rem;
    }

    .hero-icon img {
        width: 40px;
        margin-bottom: 0.5rem;
    }

    .hero-icon span {
        font-size: 0.8rem;
    }

    .content-section {
        padding: 1rem;
    }
}