/* Blueberry.css */

/* Container for entire page */
.Solar-container {
    width: 100%;
    margin: 0 auto;
    font-family: "Georgia", serif;
    /* or your preferred font */
    color: #333;
    margin-top: 5vh;
}

/* Content area (title + text + images) */
.Solar-content {
    padding: 2rem 1rem;
    max-width: 1200px;
    /* or any container width */
    margin: 0 auto;
}

/* Title style */
.Solar-title {
    font-size: 2rem;
    margin-bottom: 2rem;
}

/* Info section: text left, images right */
.Solar-info {
    display: flex;
    flex-wrap: wrap;
    /* responsive fallback */
    gap: 2rem;
    align-items: flex-start;
}

/* Left text column */
.Solar-text {
    flex: 1;
    min-width: 300px;
}

.Solar-text h2 {
    font-size: 1.3rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.Solar-text p {
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #555;
}

/* Right column: 2×2 image grid */
.Solar-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    flex-basis: 400px;
    /* ensures some consistent space for images */
}

.Solar-images img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
}

/* Yellow strip for other produces */
.Solar-other-produces {
    background-color: #F0AD3180;
    /* or your brand's yellow color */
    padding: 2rem 1rem;
    text-align: center;
}

.Solar-other-produces h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    color: #1f1e13;
    /* dark text on yellow background */
}

/* The row of produce icons/labels */
.other-produces-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.produce-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    color: #1f1e13;
    text-decoration: none;
}

.produce-item img {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

.produce-item span {
    font-size: 1rem;
    font-weight: 500;
}

/* Responsive media query */
@media (max-width: 768px) {

    .Solar-text,
    .Solar-images {
        flex: 1 1 100%;
    }

    .Solar-images {
        grid-template-columns: 1fr 1fr;
    }
}