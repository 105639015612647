/* Gallery.css */

/* Main container */
.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: "Georgia", serif;
    color: #333;
    margin-top: 5vh;
}

/* Title & subtitle */
.gallery-title {
    font-size: clamp(1.5rem, 5vw, 2rem);
    margin-bottom: 0.5rem;
}

.gallery-subtitle {
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    margin-bottom: 2rem;
    color: #666;
}

/* Section title for "Albums" and "Pictures" */
.gallery-section-title {
    font-size: clamp(1.1rem, 4vw, 1.3rem);
    margin: 2rem 0 1rem;
    color: #333;
}

/* ALBUMS - horizontal row (or flex-wrap if too many) */
.gallery-albums {
    display: flex;
    flex-wrap: wrap;
    gap: 4vw;
    margin-bottom: 2rem;
    justify-content: center;
}

/* Each album card */
.album-card {
    width: clamp(220px, 40vw, 280px);
    background-color: #fff;
    border: 1px solid #d8c65f;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.2s;
}

.album-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* The album cover image */
.album-cover img {
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    /* Maintain consistent aspect ratio */
    display: block;
    object-fit: cover;
}

/* The album info area */
.album-info {
    padding: 1rem;
}

.album-info h3 {
    font-size: clamp(0.9rem, 3vw, 1rem);
    margin-bottom: 0.5rem;
    color: #333;
    white-space: pre-line;
}

.album-link {
    background-color: transparent;
    border: none;
    color: #8c993e;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    font-size: clamp(0.8rem, 2.5vw, 0.9rem);
}

.album-link:hover {
    text-decoration: underline;
}

/* PICTURES GRID */
.gallery-pictures-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(40vw, 200px), 1fr));
    gap: clamp(0.5rem, 2vw, 1rem);
    margin-bottom: 2rem;
}

/* Each picture item */
.picture-item {
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
    transition: transform 0.2s;
    aspect-ratio: 1/1;
    /* Square aspect ratio for consistent layout */
}

.picture-item:hover {
    transform: scale(1.02);
}

.picture-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 4px;
}

/* "More pictures" button */
.gallery-more {
    text-align: center;
}

.gallery-more button {
    background-color: #d8c65f;
    border: none;
    border-radius: 20px;
    padding: clamp(0.4rem, 1.5vw, 0.6rem) clamp(1rem, 3vw, 1.5rem);
    font-size: clamp(0.9rem, 3vw, 1rem);
    cursor: pointer;
}

.gallery-more button:hover {
    background-color: #c5b24d;
}

/* Modal styles */
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-image-container {
    position: relative;
    width: 90vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image-container img {
    max-width: 90vw;
    max-height: 70vh;
    object-fit: contain;
    display: block;
}

.image-counter {
    position: absolute;
    bottom: -5vh;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    font-size: clamp(0.8rem, 2.5vw, 0.9rem);
}

.modal-nav-button {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border: none;
    border-radius: 50%;
    width: clamp(40px, 10vw, 50px);
    height: clamp(40px, 10vw, 50px);
    font-size: clamp(18px, 6vw, 24px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1010;
}

.modal-nav-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.modal-nav-left {
    left: 3vw;
}

.modal-nav-right {
    right: 3vw;
}

.modal-close-button {
    position: absolute;
    top: 3vh;
    right: 3vw;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: clamp(30px, 8vw, 40px);
    height: clamp(30px, 8vw, 40px);
    font-size: clamp(18px, 5vw, 24px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    z-index: 1010;
}

.modal-close-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Mobile close button - always visible */
.mobile-close-button {
    display: none;
    /* Hidden by default, shown only on mobile */
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
    z-index: 1020;
    /* Higher than other buttons */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mobile-close-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Specific mobile optimizations */
@media (max-width: 768px) {
    .gallery-container {
        padding: 1rem 0.5rem;
    }

    .gallery-albums {
        gap: 3vw;
    }

    .album-card {
        width: 45vw;
        /* Two albums per row on mobile */
    }

    .gallery-pictures-grid {
        grid-template-columns: repeat(2, 1fr);
        /* Always 2 columns on small screens */
    }

    /* Fullscreen modal for mobile */
    .image-modal-overlay {
        background-color: #000;
    }

    .image-modal-content {
        width: 100vw;
        height: 100vh;
    }

    .modal-image-container {
        width: 100vw;
        height: 100vh;
    }

    .modal-image-container img {
        max-width: 100vw;
        max-height: 100vh;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .modal-nav-button {
        width: 40px;
        height: 40px;
        font-size: 20px;
        opacity: 0.7;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .modal-nav-left {
        left: 10px;
    }

    .modal-nav-right {
        right: 10px;
    }

    /* Hide the regular close button on mobile */
    .modal-close-button {
        display: none;
    }

    /* Show the mobile close button */
    .mobile-close-button {
        display: flex;
    }

    .image-counter {
        bottom: 15px;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        border-radius: 15px;
        width: auto;
        max-width: 80px;
        margin: 0 auto;
    }
}

/* For very small screens */
@media (max-width: 480px) {
    .album-card {
        width: 90vw;
        /* One album per row on very small screens */
    }

    .gallery-pictures-grid {
        grid-template-columns: repeat(2, 1fr);
        /* Still 2 columns for pictures */
        gap: 2vw;
    }

    .picture-item {
        aspect-ratio: 1/1;
    }

    .modal-nav-button {
        width: 32px;
        height: 32px;
        font-size: 16px;
    }
}

/* For landscape orientation on mobile */
@media (max-width: 900px) and (orientation: landscape) {
    .modal-image-container img {
        max-height: 100vh;
        max-width: 100vw;
    }

    .image-counter {
        bottom: 10px;
    }

    .modal-nav-button {
        top: 50%;
        width: 36px;
        height: 36px;
    }

    .mobile-close-button {
        top: 10px;
        right: 10px;
    }
}