/* Updated Footer.css to match both desktop and mobile designs */

.site-footer {
    width: 100%;
    background-color: #fff;
    font-family: Arial, sans-serif;
    padding: 1.5rem;
    box-sizing: border-box;
    border-top: 1px solid #f0f0f0;
    position: relative;
}

/* Main content container */
.footer-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    position: relative;
    flex-wrap: wrap;
}

/* Logo section */
.footer-brand {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.footer-logo {
    height: 24px;
    width: auto;
    object-fit: contain;
}

.footer-company-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

/* Contact sections (email and phone) */
.footer-contact {
    display: flex;
    align-items: center;
}

.footer-contact-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.footer-icon {
    color: #ffc107;
    /* Yellow color for phone icon */
    font-size: 1.2rem;
}

.email-icon {
    color: #ffc107;
    /* Yellow color for email icon */
    font-size: 1.2rem;
}

.contact-links {
    display: flex;
    flex-direction: column;
}

.contact-links a {
    color: #0066cc;
    text-decoration: none;
    font-size: 0.9rem;
    line-height: 1.4;
}

.contact-links a:hover {
    text-decoration: underline;
}

/* Social media section */
.footer-social {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.social-title {
    display: none;
}

.footer-social a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.instagram-icon {
    color: #C13584;
    /* Instagram brand color */
    font-size: 1.5rem;
}

.facebook-icon {
    color: #4267B2;
    /* Facebook brand color */
    font-size: 1.5rem;
}

.social-text {
    display: none;
}

/* Bee illustration */
.footer-bee-container {
    margin-left: auto;
    position: relative;
}

.footer-bee {
    width: 80px;
    height: auto;
    opacity: 0.9;
}

/* Copyright section */
.footer-copyright {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.footer-copyright p {
    font-size: 0.8rem;
    color: #666;
    margin: 0;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .site-footer {
        padding: 1.5rem 1rem;
        text-align: left;
    }

    .footer-content {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
    }

    .footer-brand {
        margin-bottom: 0.5rem;
    }

    .footer-logo {
        height: 32px;
        /* Slightly larger logo on mobile */
    }

    .footer-contact {
        width: 100%;
    }

    .footer-contact-item {
        width: 100%;
    }

    /* Move bee to top-right corner */
    .footer-bee-container {
        position: absolute;
        top: 0;
        right: 0;
    }

    /* Adjust social media section */
    .footer-social {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8rem;
        margin-top: 0.5rem;
    }

    .social-title {
        display: block;
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 0.5rem;
    }

    .footer-social .social-icons {
        display: flex;
        gap: 1rem;
    }

    /* Adjust copyright text */
    .footer-copyright {
        margin-top: 2rem;
        justify-content: center;
    }
}