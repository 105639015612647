/* Main Produce Section with a clean background */
.produce-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3% 3%;
    background: linear-gradient(135deg, #1a1a0e, #26261a);
    color: #2c2c17;
    min-height: 350px;
    overflow: hidden;
    flex-wrap: wrap;
    text-decoration: none;
}

.produce-card {
    flex: 1;
    max-width: 65ch;
    background-color: #f5f3d7;
    border-radius: 12px;
    padding: 1.5rem 2rem;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}



.produce-card:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.card-content {
    position: relative;
}

.produce-card h2 {
    font-size: clamp(1.2rem, 2vw, 1.8rem);
    margin-bottom: 1rem;
    color: #2c2c17;
}

.produce-card p {
    line-height: 1.6;
    font-size: clamp(0.9rem, 1vw, 1rem);
}

.parent {
    --aspect-ratio: 1/cos(30deg);
    --border-width: 2px;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr;
    grid-template-rows: repeat(4, 0.75fr);
    width: 140%;
    max-width: 70svw;
}

.parent>*>div {
    margin: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    max-width: 100%;
    justify-items: center;
}

.parent>* {
    text-decoration: none;
    aspect-ratio: var(--aspect-ratio);
    clip-path: polygon(50% -50%, 100% 50%, 50% 150%, 0 50%);

    /*accounts for padding issues and overlay stuff*/
    box-sizing: border-box;
    isolation: isolate;

    /*sizing stuff and layout*/
    height: 14rem;
    display: grid;
    place-items: center;
    grid-template-columns: 70%;
    grid-template-rows: 40% 40%;
    margin: 10px;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    padding: 2rem;

    position: relative;
    align-content: center;
}

.parent>*>* {
    z-index: 1;
}

.parent>*::after {
    content: "";
    position: absolute;
    z-index: 0;
    inset: calc(var(--border-width) * var(--aspect-ratio)) var(--border-width);

    aspect-ratio: 1/cos(30deg);
    clip-path: polygon(50% -50%,
            100% 50%,
            50% 150%,
            0 50%);

    background-color: #211801;
    transition: background-image 0.3s ease;
}

.parent>*::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;

    background-image: linear-gradient(to bottom, #473F23, #F6CC4F);
}

.parent>*>span {
    flex-wrap: nowrap;
    text-wrap: nowrap;
    color: #F6CC4F;
}

.parent>*:hover::after {
    background-color: #e7c04b;
    ;
    /* Change background on hover */
}

.parent>*:hover::before {
    background-image: linear-gradient(to bottom, #473F23, #F6CC4F);
    /* Change gradient on hover */
}

.parent>*:hover img,
.parent>*:hover span {
    filter: invert(14%) sepia(28%) saturate(654%) hue-rotate(357deg) brightness(55%) contrast(95%);
}

.parent>* span {
    flex-wrap: nowrap;
    text-wrap: nowrap;

    color: #F6CC4F;
}

.card-icon {
    height: 5vh;
    width: auto;
}

.div5,
.div6 {
    background-color: #F6CC4F !important;
}

.div5::before,
.div5::after,
.div6::before,
.div6::after {
    content: none;
}

.div1 {
    grid-area: 2 / 1 / 4 / 4;
}

.div2 {
    grid-area: 2 / 5 / 4 / 8;
}

.div3 {
    grid-area: 2 / 9 / 4 / 12;
}

.div4 {
    grid-area: 1 / 3 / 3 / 6;
}

.div5 {
    grid-area: 3 / 3 / 5 / 6;
}

.div6 {
    grid-area: 1 / 7 / 3 / 10;
}

.div7 {
    grid-area: 3 / 7 / 5 / 10;
}

@media screen and (min-width: 1921px) {
    .parent>* {
        height: 18rem;
        /* Reduce height from original 14rem */
    }
}

/* For screens between 1100px and 1450px - move produce card lower and make divs smaller */
@media screen and (min-width: 1051px) and (max-width: 1450px) {
    .produce-section {
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
    }

    .parent {
        margin-bottom: 2rem;
        /* Makes the entire hexagon grid smaller */
    }


    .parent>* {
        width: 19vmin;
        height: var(--aspect-ratio);
        margin: 0;
    }

    .produce-card {
        max-width: 80%;
    }
}

/* For screens between 800px and 1050px - remove margins, make 100% width, and smaller divs */
@media screen and (min-width: 800px) and (max-width: 1050px) {
    .produce-section {
        padding: 3% 0;
    }

    .parent {
        width: 100%;
        max-width: 100%;
        margin: 0;
        transform: scale(0.8);
        /* Makes the entire hexagon grid smaller */
    }

    .parent>* {
        height: 11rem;
        /* Even smaller for this breakpoint */
        padding: 1.5rem;
        /* Reduce padding */
    }

    .parent>*>span {
        font-size: 1.3rem;
        /* Smaller text */
    }

    .produce-card {
        max-width: 95%;
        margin: 1rem 0;
    }
}


@media screen and (width <=800px) {
    .parent {
        width: 100%;
        margin: 5vmin auto;
        grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr;
        grid-template-rows: repeat(6, 0.75fr);
    }

    /* center -> top -> clockwise */
    .div1 {
        grid-area: 3 / 3 / 5 / 6;
    }

    .div2 {
        grid-area: 1 / 3 / 3 / 6;
    }

    .div3 {
        grid-area: 2 / 5 / 4 / 8;
    }

    .div4 {
        grid-area: 4 / 5 / 6 / 8;
    }

    .div5 {
        grid-area: 5 / 3 / 7 / 6;
    }

    .div6 {
        grid-area: 4 / 1 / 6 / 4;
    }

    .div7 {
        grid-area: 2 / 1 / 4 / 4;
    }

    .produce-section {
        flex-direction: column;
    }

    .parent>* {
        width: 29vmin;
        height: var(--aspect-ratio);
        margin: 0;
    }

    .parent>*>span {
        font-size: 3vmin;
        margin-top: 5vmin;
    }

    img {
        width: 11vmin;
        height: auto;
    }
}

@media screen and (width <400px) {}